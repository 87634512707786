.home {
  width: 100%;
  align-items: center;
  font-family: "Raleway", sans-serif;
  color: hsl(197, 37%, 24%);
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: hsl(197, 37%, 24%);
  color: hsl(43, 74%, 66%);
}
.flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: flex-start; */
}
.flex-start p {
  margin-top: 0;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}
.about h2 {
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  color: hsla(180, 100%, 100%, 0.784);
  height: 50px;
}
.about p {
  /* width: 40%; */
  font-size: 30px;
}

.about .prompt {
  width: 40%;
  margin-top: 100px;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: hsla(180, 100%, 100%, 0.784);
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 80px;
  color: hsl(197, 37%, 24%);
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}

.roundedImg {
  margin-top: 45px;
  margin-right: 2rem;
  border: 8px solid hsla(0, 0%, 0%, 0.35);
  /* opacity: 0.50; */
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.profilPicture {
  width: 110%;
  display: block;
  margin-left: -2%;
  margin-right: auto;
  transform: scaleX(-1);
}

.flex-horizon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width: 911px) {
  .flex-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
  }
}
@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 60px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
  .roundedImg {
  margin-top: 45px;
  margin-right: 2rem;
  border: 8px solid hsla(0, 0%, 0%, 0.35);
  /* opacity: 0.50; */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
}
.profilPicture {
  width: 110%;
  display: block;
  margin-left: -2%;
  margin-right: auto;
  transform: scaleX(-1);
}

}
