.footer {
  width: 100%;
  height: 200px;
  background: hsl(197, 37%, 24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 200px;
}

.socialMedia svg {
  color: hsla(180, 100%, 100%, 0.784);
  margin: 20px;
  font-size: 60px;
  cursor: pointer;
}

.footer p {
  color: hsla(180, 100%, 100%, 0.784);
}

@media only screen and (max-width: 600px) {
  .socialMedia svg {
    font-size: 50px;
  }
}