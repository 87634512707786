.project {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: hsla(180, 100%, 100%, 0.784);
}

.project h1 {
  /* margin-top: 10%; */
  color: hsl(197, 37%, 24%);
  font-size: 35px;
}
.project img {
  border-radius: 10px;
}
.imgSize {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  width: 90%;
  overflow: hidden;
}
.project p {
  font-size: 40px;
  color: hsl(197, 37%, 24%);
}

.project svg {
  font-size: 60px;
  color: hsl(197, 37%, 24%);
}

.titleUndo {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .project img {
    width: 90%;
    border-radius: 10px;
  }
}
